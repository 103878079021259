import React, { useRef, useEffect, useState } from 'react';
import './index.css';
import fourth2Mobile from '../../assets/videos/mobile/4-2-mobile.mp4';

const FourthSecond = () => {
  const [isInViewportSecond, setIsInViewportSecond] = useState(false);
  const splineRefSecond = useRef(null);

  const handleIntersectionSecond = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewportSecond(true);
      } else {
        setIsInViewportSecond(false);
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerSecond = new IntersectionObserver(
      handleIntersectionSecond,
      options,
    );

    if (splineRefSecond.current) {
      observerSecond.observe(splineRefSecond.current);
    }
    return () => {
      if (splineRefSecond.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerSecond.unobserve(splineRefSecond.current);
      }
    };
  }, []);

  const handleBasic = () => {
    window.open('https://custellax.com/', '_blank');
  };

  return (
    <>
      <section className="w-full h-screen bg-black relative flex flex-col xs:pt-[8px] sm:pt-[120px]">
        <div className="relative flex flex-col">
          <div className="flex flex-col items-center">
            <div className="h-auto bg-black xs:pt-[180px] sm:pt-[128px] justify-center flex-shrink-0">
              <div
                className="relative flex items-center justify-center"
                ref={splineRefSecond}
              >
                {isInViewportSecond && (
                  <div
                    style={{
                      width: '140px',
                      height: '140px',
                      transition: 'opacity 0.5s ease-in-out',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      muted="muted"
                      autoplay="autoplay"
                      loop="loop"
                      playsInline
                    >
                      <source src={fourth2Mobile} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center text-center xs:mt-[16px] sm:mt-[24px]">
                <p className="font-SubTitle text-white xs:text-[16px] sm:text-[20px] text-[28px] xs:mt-[28px] sm:mt-[24px]">
                  custella
                </p>
                <p className="font-SubDetail text-primary xs:text-[12px] sm:text-[16px] xs:mt-[16px] sm:mt-[16px]">
                  Safe and reliable digital assets
                  <br />
                  Custody solution
                </p>
                <button
                  onClick={handleBasic}
                  className="grad-btn bg-black xs:w-24 xs:h-9 sm:w-24 sm:h-9 xs:rounded-[24px] sm:rounded-[24px] w-[108px] h-9  border-1 text-white xs:text-[12px] xs:mt-[24px] sm:text-[14px] sm:mt-[24px] text-[14px] mt-[52px] flex items-center justify-center"
                >
                  <div className="bg-black xs:w-[92px] xs:h-[32px] sm:w-[92px] sm:h-[32px] xs:rounded-[24px] sm:rounded-[24px] w-[104px] h-8 rounded-[42px] border-none flex justify-center items-center">
                    Explore
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FourthSecond;
